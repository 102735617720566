import React from "react";
import { EbookDescriptionSection } from "../components/Ebook/EbookDescriptionSection/EbookDescriptionSection";
import { EbookDisclaimer } from "../components/Ebook/EbookDisclaimer/EbookDisclaimer";
import { EbookOrientedOnSolutionsSection } from "../components/Ebook/EbookOrientedOnSolutionsSection/EbookOrientedOnSolutionsSection";
import { EbookTextSection } from "../components/Ebook/EbookTextSection/EbookTextSection";
import { EmailComponentSection } from "../components/Shared/EmailComponentSection/EmailComponentSection";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../components/Shared/Layout/Layout";

export interface EbookPageProps {
  className?: string;
}

const EbookPage = () => (
  <Layout>
    <TopHeader title="Ebook for you" />
    <EbookTextSection />
    <EbookDescriptionSection />
    <EbookDisclaimer />
    <EmailComponentSection />
    <EbookOrientedOnSolutionsSection />
  </Layout>
);

export default EbookPage;
